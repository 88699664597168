import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = ({ siteTitle }) => (
    <StaticQuery
        query={graphql`
        query {
            placeholderImage: file(relativePath: { eq: "afv_large.jpg" }) {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
      `}
        render={data => (<footer className="Footer" role="contentinfo" id="yui_3_17_2_1_1538632099177_75">
            <div className="Footer-inner clear" id="yui_3_17_2_1_1538632099177_74">
                <div className="sqs-layout sqs-grid-12 columns-12 Footer-blocks Footer-blocks--top sqs-alternate-block-style-container empty" id="footerBlocksTop">
                    <div className="row sqs-row">
                        <div className="col sqs-col-12 span-12"></div>
                    </div>
                </div>
                <div className="Footer-middle" id="yui_3_17_2_1_1538632099177_73">
                    <div className="Footer-business">
                    </div>
                    <div className="sqs-layout sqs-grid-12 columns-12 Footer-blocks Footer-blocks--middle sqs-alternate-block-style-container" id="footerBlocksMiddle">
                        <div className="row sqs-row" id="yui_3_17_2_1_1538632099177_72">
                            <div className="col sqs-col-12 span-12" id="yui_3_17_2_1_1538632099177_71">
                                <div className="row sqs-row" id="yui_3_17_2_1_1538632099177_70">
                                    <div className="col-xs-12 col-md-4" id="yui_3_17_2_1_1538632099177_69">
                                        <div className="sqs-block image-block sqs-block-image sqs-text-ready" id="block-yui_3_17_2_1_1528251981657_81574">
                                            <div className="sqs-block-content" id="yui_3_17_2_1_1538632099177_68">
                                                <div className="image-block-outer-wrapper layout-caption-below design-layout-inline   "
                                                    id="yui_3_17_2_1_1538632099177_67">
                                                    <div className="intrinsic" style={{ maxWidth: "625.0px" }} id="yui_3_17_2_1_1538632099177_66">
                                                        <div style={{ paddingBottom: "30.88%", overflow: "hidden" }} className="image-block-wrapper   has-aspect-ratio" id="yui_3_17_2_1_1538632099177_65">
                                                            <noscript><img src={data.placeholderImage.childImageSharp.original.src}
                                                                alt="anderson" /></noscript>
                                                            <img className="thumb-image loaded"
                                                                data-src={data.placeholderImage.childImageSharp.original.src}
                                                                data-image={data.placeholderImage.childImageSharp.original.src}
                                                                data-image-dimensions="625x193" data-image-focal-point="0.5,0.5"
                                                                data-load="false" data-image-id="5b1755da6d2a73c2f8d70dd7"
                                                                data-type="image" data-position-mode="standard" alt="anderson"
                                                                src={data.placeholderImage.childImageSharp.original.src}
                                                                data-image-resolution="750w" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_22_1488316984327_12075">
                                            <div className="sqs-block-content">
                                                <p className="text-align-center"><strong>Address</strong><br />20120 NE Herring Lane<br />Newberg, OR 97132</p>
                                                <p className="text-align-center"><strong>Phone Number</strong><br />503-554-5541</p>
                                                <p className="text-align-center"><strong>Email</strong><br /><a href="mailto:info@andersonfamilyvineyard.com" target="_top">info@andersonfamilyvineyard.com</a></p>
                                                <p className="text-align-center">
                                                    <a style={{ padding: "0px", border: "0px" }} target="_blank" rel="noreferrer" href="https://www.facebook.com/AndersonFamilyVineyard">
                                                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                                                    </a>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a style={{ padding: "0px", border: "0px" }} target="_blank" rel="noreferrer" href="https://www.instagram.com/andersonfamilyvineyard">
                                                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_22_1488316984327_13661">
                                            <div className="sqs-block-content">
                                                <p className="text-align-center"><strong>VISIT US</strong><br />We are appointment only.</p>
                                            </div>
                                        </div>
                                        <div className="sqs-block button-block sqs-block-button">
                                            <div className="sqs-block-content" id="yui_3_17_2_1_1538632099177_94">
                                                <div className="sqs-block-button-container--center">
                                                    <a href="https://friends.andersonfamilyvineyard.com/public/visit" className="sqs-block-button-element--medium sqs-block-button-element">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)}
    />
)

export default Footer
