import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"


const MobileHeader = ({ siteImage, menuItems }) => (<StaticQuery
   query={graphql`
      query {
         file(relativePath: { eq: "ui-icons.svg" }) {
            publicURL
         }
      }
`}
   render={function (data) {
      function handleClick() {
         document.getElementById("collection-5ad7a4aff950b7232cd6d4a8").classList.toggle("is-mobile-overlay-active");
      }

      return (<div className="Mobile loaded" data-nc-base="mobile-bar" data-controller="AncillaryLayout" data-controllers-bound="AncillaryLayout" style={{ backgroundColor: "#000" }}>
         <div className="Mobile-bar Mobile-bar--top" data-nc-group="top" data-controller="MobileOffset"
            data-controllers-bound="MobileOffset" style={{ backgroundColor: "#000" }}>
            <div data-nc-container="top-left"></div>
            <div data-nc-container="top-center">
               <Link to="/" className="Mobile-bar-branding">
                  <img src={siteImage} alt="Anderson Family Vineyard" className="Mobile-bar-branding-logo" style={{ width: "220px" }} />
               </Link>
            </div>
            <div data-nc-container="top-right">
               <button className="Mobile-bar-menu" data-nc-element="menu-icon"
                  data-controller-overlay="menu" data-controller="MobileOverlayToggle" data-controllers-bound="MobileOverlayToggle" style={{ stroke: "#ccc", fill: "currentColor" }} onClick={handleClick}>
                  <svg className="Icon Icon--hamburger" viewBox="0 0 24 18">
                     <use href={data.file.publicURL + "#hamburger-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#hamburger-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--hotdog" viewBox="0 0 24 14">
                     <use href={data.file.publicURL + "#hotdog-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#hotdog-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--plus" viewBox="0 0 20 20">
                     <use href={data.file.publicURL + "#plus-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#plus-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--dots-horizontal" viewBox="0 0 25 7">
                     <use href={data.file.publicURL + "#dots-horizontal-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#dots-horizontal-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--dots-vertical" viewBox="0 0 7 25">
                     <use href={data.file.publicURL + "#dots-vertical-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#dots-vertical-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--squares-horizontal" viewBox="0 0 25 7">
                     <use href={data.file.publicURL + "#squares-horizontal-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#squares-horizontal-icon--odd"} className="use--odd"></use>
                  </svg>
                  <svg className="Icon Icon--squares-vertical" viewBox="0 0 7 25">
                     <use href={data.file.publicURL + "#squares-vertical-icon--even"} className="use--even"></use>
                     <use href={data.file.publicURL + "#squares-vertical-icon--odd"} className="use--odd"></use>
                  </svg>
               </button>
            </div>
         </div>
         <div className="Mobile-bar Mobile-bar--bottom" data-nc-group="bottom" data-controller="MobileOffset"
            data-controllers-bound="MobileOffset">
            <div data-nc-container="bottom-left">
            </div>
            <div data-nc-container="bottom-center">
            </div>
            <div data-nc-container="bottom-right">
            </div>
         </div>
         <div className="Mobile-overlay">
            <div className="Mobile-overlay-menu" data-controller="MobileOverlayFolders" data-controllers-bound="MobileOverlayFolders" >
               <div className="Mobile-overlay-menu-main" style={{ width: "360px", right: "0px", left: "0px" }}>
                  <nav className="Mobile-overlay-nav Mobile-overlay-nav--primary" data-content-field="navigation" style={{ color: "#818181", position: "relative", top: "25%", width: "100%", marginTop: "30px", textAlign: "center" }}>
                     {
                        menuItems.map((item, index) => {
                           //return <Link key={index} to={item.relativeUrl} className="Mobile-overlay-nav-item">{item.name}</Link>
                           return <a key={index} href={item.fqUrl} className="Mobile-overlay-nav-item" style={{ fontSize: "36px", textAlign: "center" }}>{item.name}</a>
                        })
                     }
                  </nav>
                  <nav className="Mobile-overlay-nav Mobile-overlay-nav--secondary" data-content-field="navigation">
                  </nav>
               </div>
               <div className="Mobile-overlay-folders" data-content-field="navigation">
               </div>
            </div>
            <button className="Mobile-overlay-close" data-controller="MobileOverlayToggle" data-controllers-bound="MobileOverlayToggle" onClick={handleClick} >
               <svg className="Icon Icon--close" viewBox="0 0 16 16">
                  <use href={data.file.publicURL + "#close-icon"}></use>
               </svg>
            </button>
            <div className="Mobile-overlay-back" data-controller="MobileOverlayToggle" data-controllers-bound="MobileOverlayToggle"></div>
         </div>
      </div>
      )
   }
   }
/>
)

export default MobileHeader
