import { Link } from "gatsby"
import React from "react"

class Header extends React.Component {
  render() {
    return (<div className="Site loaded" data-nc-base="header" data-controller="AncillaryLayout" id="yui_3_17_2_1_1538632099177_76"
      data-controllers-bound="AncillaryLayout">
      <header className="Header Header--top">
        <div className="Header-inner Header-inner--top" data-nc-group="top">
          <div data-nc-container="top-left">
            <Link to="/" className="Header-branding">
              <img src={this.props.siteImage} alt="Anderson Family Vineyard" className="Header-branding-logo" />
            </Link>
          </div>
          <div data-nc-container="top-center"></div>
          <div data-nc-container="top-right">
            <nav className="Header-nav Header-nav--primary" data-nc-element="primary-nav" data-content-field="navigation">
              <div className="Header-nav-inner">
                {
                  this.props.menuItems.map((item, index) => {
                    //return <Link key={index} to={item.relativeUrl} className="Header-nav-item">{item.name}</Link>
                    return <a key={index} href={item.fqUrl} className="Header-nav-item">{item.name}</a>
                  })
                }
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>)
  }
}

export default Header
